/// <reference path="../app.ts" />
namespace Advant.Documents  {
    "use strict";

    interface IForgotPassword {
        securityQuestion: string;
        questionId: string;
        email: string;
        answer: string;
        tenant: string;
    }

    class ForgotPassword implements IForgotPassword {
        securityQuestion: string;
        questionId: string;
        email: string;
        answer: string;
        tenant: string;
        errors: Array<any> = [];
        showConfirmation: boolean = false;
        busy: boolean = false;

        static $inject: string[] = ["$location", "authentication", "resetPassword"];

        constructor(private $location: angular.ILocationService,
            private authentication: IAuthenticationProviderService,
            private resetPassword: IResetPassword) {

            this.tenant = this.resetPassword.resetPasswordInfo.tenant;
        }

        getSecurityQuestion = () => {
            this.errors = [];
            this.busy = true;
            this.authentication.getSecurityQuestion({ email: this.email, tenant: this.tenant }).then((result) => {
                this.questionId = result.data.passwordResetSecretID;
                this.securityQuestion = result.data.question;
                this.busy = false;
            }, (reason) => {
                this.busy = false;
                if (reason.status === 404) {
                    this.errors.push("We do not have a record of this user in our system.");
                } else {
                    this.errors.push("An error occurred while attempting to retrieve your security question.");
                }
            });
        };

        changePassword = () => {
            this.errors = [];
            this.busy = true;
            var passwordResetModel: IPasswordResetWithSecretInputModel = {
                email: this.email,
                tenant: this.tenant,
                questionId: this.questionId,
                answer: this.answer
            };

            this.authentication.changePasswordWithSecret(passwordResetModel).then(() => {
                this.busy = false;
                this.showConfirmation = true;
            }, (reason) => {
                this.busy = false;
                if (reason.data.error_description) {
                    this.errors.push(reason.data.error_description);
                }
                else if (reason.data.message) {
                    this.errors.push(reason.data.message);
                }
                else {
                    this.errors.push("An error occurred while attempting to retrieve your security question.");
                }
            });
        };
    }

    angular.module("app").controller("forgotPassword", ForgotPassword);
}
/// <reference path="../app.ts" />

namespace Advant.Documents {
    export interface ISidebarController {
        isCurrent: (route: IRoute) => boolean;
        navRoutes: Array<IRoute>;
    }



    export class SidebarController implements ISidebarController {
        static controllerId: string = "sidebarController";
        static $inject: any = ["$location", "$route", "common", "config", "routes"];
        navRoutes: Array<IRoute> = [];

        constructor(private $location: angular.ILocationService,
            private $route: angular.route.IRouteService,
            private common: ICommonService,
            private config: Advant.Documents.ICrossroadsConfig,
            private routes: Array<IRoute>) {

            this.getNavRoutes();
        }

        private getNavRoutes() {
            this.navRoutes = this.routes
                .filter((r, i, a) => {
                var val: boolean = (r.config.settings && r.config.settings.navOrder !== undefined);

                var allowed: boolean = false;

                var userInfo = this.common.getUser();
                if (r.permission) {
                    // Does an OR join on the permissions array
                    allowed = false;
                    angular.forEach(r.permission,(permission, key) => {
                        if (userInfo[permission]) {
                            allowed = userInfo[permission];
                        }
                    });

                } else {
                    allowed = true;
                }
                return val && allowed;
            })
                .sort((r1, r2) => r1.config.settings.navOrder - r2.config.settings.navOrder);

            // filters and sorts the sub navs for the routes
            this.navRoutes.forEach((route) => {
                if (route.subNavs) {
                    route.subNavs = route.subNavs.filter((r, i, a) => {
                        var val = (r.config.settings && r.config.settings.navOrder !== undefined);
                        return val;
                    })
                        .sort((r1, r2) => r1.config.settings.navOrder - r2.config.settings.navOrder);
                }
            });
        }

        isCurrent(route: IRoute): boolean {
            if (!route.config.section || !this.$route.current || !(<any>this.$route.current).section) {
                return false;
            }
            var menuName = route.config.section;
            return (<any>this.$route.current).section.substr(0, menuName.length) === menuName;
        }

    }

    angular.module("app").controller(SidebarController.controllerId, SidebarController);
}

/// <reference path="../app.ts" />
namespace Advant.Documents {
    import Moment = moment.Moment;


    export interface IDashboardController {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
    }


    export class DashboardController implements IDashboardController {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        pendingRequests: IDocumentsRequest[];
        completedRequests: IDocumentsRequest[];
        uploadingDocument: boolean;
        userInfo = this.common.getUser();

        static $inject: Array<string> = ["$scope", "Restangular", "common", "config", "Upload", "helper"];

        constructor(private $scope: angular.IScope, private Restangular: Restangular.IService, private common: ICommonService, private config: ICrossroadsConfig, private Upload: angular.angularFileUpload.IUploadService, private helper: IHelper) {

            this.log = common.logger.getLogFn("dashboardController");
            this.logError = common.logger.getLogFn("dashboardController", "error");
            this.logSuccess = common.logger.getLogFn("dashboardController", "success");

            this.uploadingDocument = false;

            this.activate();

            $scope.$on(config.events.customerChanged, (event) => {
                this.activate();
            });

        }


        activate() {
            this.common.activateController([this.getPendingRequests(), this.getCompletedRequests()], "dashboardController")
                .then(() => { this.log("Activated Dashboard View"); });
        }


        getPendingRequests = (): angular.IPromise<any> => {
            this.pendingRequests = [];
            var queryParams = {
                page: 1,
                pageSize: 10,
                sortDirection: "ASC",
                sortBy: "DateRequested",
                crmId: this.common.getUser().activeCustomer
            };

            return this.Restangular.all("documentsrequests/pending").getList(queryParams).then((result: any) => {
                this.pendingRequests = result;
                return result;
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the documents request", reason);
                }
                return null;
            });
        };

        getCompletedRequests = (): angular.IPromise<any> => {
            this.completedRequests = [];
            var queryParams = {
                page: 1,
                pageSize: 10,
                sortDirection: "DESC",
                sortBy: "DateCompleted",
                crmId: this.common.getUser().activeCustomer
            };

            return this.Restangular.all("documentsrequests/completed").getList(queryParams).then((result: any) => {
                this.completedRequests = result;
                return result;
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the completed documents request", reason);
                }
                return null;
            });
        };

        uploadAttachment = (documentsRequestId, file) => {
            this.uploadingDocument = true;
            this.Upload.upload(<any>{
                url: this.config.apiHost + "/" + documentsRequestId + "/attachment",
                method: "POST",
                file: file
            }).then((response) => {
                this.uploadingDocument = false;
                this.getPendingRequests();
                this.getCompletedRequests();
            });
        }

        openAttachment = (documentsRequestId) => {

            var httpPath = this.config.apiHost + "/" + documentsRequestId + "/attachment";

            this.helper.openFile(httpPath);

        }
    }

    angular.module("app").controller("dashboardController", DashboardController);

}
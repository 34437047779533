/// <reference path="../app.ts" />
namespace Advant.Documents {
    export interface IvalidSubmit extends angular.IDirective {
        link?: (scope?: IvalidSubmitScope, element?: any, attrs?: any, form?: any) => void;
    }

    export interface IvalidSubmitScope extends angular.IScope {

    }

    export class ValidSubmit implements IvalidSubmit {
        static $inject: any = ["$parse"];
        static directiveId: string = "validSubmit";
        restrict: string = "A";
        require: string = "form";

        constructor(private $parse: angular.IParseService) {
        }

        link = (scope?: IvalidSubmitScope, element?, attrs?, form?) => {
            form.$submitted = false;

            // get a hold of the function that handles submission when form is valid
            var fn = this.$parse(attrs.validSubmit);

            // register DOM event handler and wire into Angular"s lifecycle with scope.$apply
            element.on("submit", event => {
                scope.$apply(() => {
                    // on submit event, set submitted to true (like the previous trick)
                    form.$submitted = true;
                    // if form is valid, execute the submission handler function and reset form submission state
                    if (form.$valid) {
                        fn(scope, { $event: event });
                        form.$submitted = false;
                    }
                });
            });
        };
    }

    // Update the app variable name to be that of your module variable
    angular.module("app").directive(ValidSubmit.directiveId, ["$parse", $parse =>
        new ValidSubmit($parse)
    ]);
}

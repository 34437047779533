/// <reference path="app.ts" />
namespace Advant.Documents {
    "use strict";

    export interface IRouteConfigSettings {
        navOrder?: number;
        content?: string;
    }

    export interface IRouteConfig {
        templateUrl?: string;
        title?: string;
        section?: string;
        settings: IRouteConfigSettings;
        caseInsensitiveMatch?: boolean;
        controller?: string;
        controllerAs?: string;
        reloadOnSearch?: boolean;
    }

    export interface IRoute {
        url?: string;
        config?: IRouteConfig;
        subNavs?: Array<IRoute>;
        permission?: Array<string>;
    }


    export class RouteConfig {
        routes: Array<IRoute>;
        constructor() {
            this.routes = [
                {
                    url: "/login",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/loginForm.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/resetPassword",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/passwordChange.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/confirmPasswordChange",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/confirmPasswordChange.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/changeQuestion",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/securityQuestionChange.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/forgotPassword",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/forgotPassword.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/:tenant",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/loginForm.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/",
                    config: {
                        templateUrl: "/app/dashboard/dashboard.html",
                        title: "Dashboard",
                        section: "Dashboard",
                        caseInsensitiveMatch: true,
                        settings: {
                            navOrder: 1,
                            content: "<i class=\"fa fa-dashboard\"></i>Dashboard"
                        }
                    }
                }, {
                    config: {
                        section: "Requests",
                        settings: {
                            navOrder: 2,
                            content: "<i class=\"fa fa-paper-plane\"></i> Requests"
                        }
                    }, subNavs: [
                        {
                            url: "/documentsRequests/pending",
                            config: {
                                templateUrl: "/app/documentsRequests/pendingDocumentsRequests.html",
                                controller: "pendingDocumentsRequests",
                                controllerAs: "vm",
                                section: "Requests",
                                title: "Pending Document Requests",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 1,
                                    content: "<div class='row'><div class='col-xs-2'><i class=\"fa fa-envelope-o\"></i></div><div class='col-xs-10'> Pending Requests</div></div>"
                                }
                            }
                        }, {
                            url: "/documentsRequests/completed",
                            config: {
                                templateUrl: "/app/documentsRequests/completedDocumentsRequests.html",
                                controller: "completedDocumentsRequests",
                                controllerAs: "vm",
                                section: "Requests",
                                title: "Completed Document Requests",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 2,
                                    content: "<div class='row'><div class='col-xs-2'><i class=\"fa fa-envelope-open-o\"></i></div><div class='col-xs-10'> Completed Requests</div></div>"
                                }
                            }
                        }
                    ]
                }
            ];
        }
    }

    var advantRoutes = new RouteConfig();

    // Collect the routes
    angular.module("app").constant("routes", advantRoutes.routes);

    // Configure the routes and route resolvers
    function routeConfigurator($routeProvider: any, routes: any) {
        function setRoute(r: IRoute) {

            if (r.url) {
                $routeProvider.when(r.url, r.config);
            }
            if (r.subNavs) {
                r.subNavs.forEach(sn => setRoute(sn));
            }
            return $routeProvider;
        }

        routes.forEach(r => setRoute(r));

        $routeProvider.otherwise({ redirectTo: "/" });
    }

    angular.module("app").config(["$routeProvider", "routes", routeConfigurator]);
}
/// <reference path="../app.ts" />
namespace Advant.Documents {

    interface ICompletedRequests {
        requests: IDocumentsRequest[];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        searchFilter: string;
    }


    class CompletedRequests implements ICompletedRequests {
        static controllerId: string = "completedDocumentsRequests";
        static $inject: any = ["$scope", "Restangular", "common", "config", "helper"];
        public dataAvailable: boolean;
        public requests: IDocumentsRequest[];
        public page: number;
        public pageSize: number;
        public totalCount: number;
        public sortBy: string;
        public sortDirection: string;
        public totalPages: number;
        public startRow: number;
        public endRow: number;
        public searchFilter: string;
        public uploadingDocument: boolean;

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        constructor(private $scope: angular.IScope,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper) {

            this.log = common.logger.getLogFn(CompletedRequests.controllerId);
            this.logError = common.logger.getLogFn(CompletedRequests.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(CompletedRequests.controllerId, "success");
            this.requests = [];

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "DateCompleted";
            this.sortDirection = "DESC";
            this.searchFilter = "";
            

            this.activate();

            $scope.$on(config.events.customerChanged, (event) => {
                this.activate();
            });
        }

        private activate = () => {

            this.common.activateController([this.getCompletedRequests()], CompletedRequests.controllerId)
                .then(result => {
                    this.log("Activated Completed Requests View", null, false);

                });
        };


        getCompletedRequests = () => {
            this.requests = [];

            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortDirection: this.sortDirection,
                sortBy: this.sortBy,
                crmId: this.common.getUser().activeCustomer,
                filter: this.searchFilter
            };

            return this.Restangular.all("documentsrequests/completed").getList(queryParams).then((result: any) => {
                this.requests = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }

                return result;
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the documents request", reason);
                }
                return null;
            });
        };

        searchUsers = (): void => {
            this.common.debouncedThrottle("CompletedDocumentSearch", () => { this.page = 1; this.getCompletedRequests(); }, 400);
        };

        sort(sortValue: string) {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
                this.sortDirection = "ASC";
            }
            this.page = 1;
            this.getCompletedRequests();
        }

        openAttachment = (documentsRequestId) => {

            var httpPath = this.config.apiHost + "/" + documentsRequestId + "/attachment";

            this.helper.openFile(httpPath);

        }
    }

    angular.module("app").controller(CompletedRequests.controllerId, CompletedRequests);
}

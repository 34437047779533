/// <reference path="../app.ts" />

namespace Advant.Documents  {
    "use strict";

    interface ISecurityQuestionChangScope extends angular.IScope {
        vm: ISecurityQuestionChange ;
        questionForm: angular.IFormController;
    }

    interface ISecurityQuestionChange {
        securityChangeInfo: IForceSecurityQuestionChangeModel;
    }

    class SecurityQuestionChange implements ISecurityQuestionChange {
        securityChangeInfo: IForceSecurityQuestionChangeModel;
        errors: Array<any> = [];
        busy: boolean;
        loginInfo: ILoginModel;

        static $inject: any = ["$scope", "$location", "authentication", "resetPassword"];

        constructor(private $scope: ISecurityQuestionChangScope,
            private $location: angular.ILocationService,
            private authentication: IAuthenticationProviderService,
            private resetPassword: IResetPassword) {

            this.securityChangeInfo = {
                userName: resetPassword.resetPasswordInfo.userName,
                password: resetPassword.resetPasswordInfo.password,
                question: "",
                answer: "",
                tenant: resetPassword.resetPasswordInfo.tenant
            };
            this.busy = false;
        }

        changeQuestion() {
            var self = this;
            self.errors = [];
            if (self.$scope.questionForm.$valid) {
                self.busy = true;

                self.authentication.forceSecurityQuestionChange(self.securityChangeInfo).then(result=> {
                    self.loginInfo = {
                        client_id: self.resetPassword.resetPasswordInfo.client_id,
                        userName: self.resetPassword.resetPasswordInfo.userName,
                        password: self.securityChangeInfo.password,
                        rememberMe: self.resetPassword.resetPasswordInfo.rememberMe,
                        tenant: self.resetPassword.resetPasswordInfo.tenant,
                        grant_type: self.resetPassword.resetPasswordInfo.grant_type
                    };
                    self.login();
                }, reason => {
                    if (reason.data.error_description) {
                        self.errors.push(reason.data.error_description);
                    } else {
                        self.errors.push("An error occurred while logging in. Please try again later.");
                    }

                    self.busy = false;

                });
            }
        }

        login() {
            var self = this;

            self.errors = [];

            self.authentication.login(self.loginInfo).then(result=> {
                self.busy = false;
            }, reason=> {
                if (reason.data.error_description) {
                    self.errors.push(reason.data.error_description);
                } else {
                    self.errors.push("An error occurred while logging in. Please try again later.");
                }

                self.busy = false;
            });
        }

       
    }

    angular.module("app").controller("securityQuestionChange", SecurityQuestionChange);
}
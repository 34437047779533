/// <reference path="../app.ts" />
namespace Advant.Documents {
    export interface ILoginControllerScope extends angular.IScope {
        vm: LoginController;
        loginForm: angular.IFormController;
    }

    export interface ILoginRouteParams extends angular.route.IRouteParamsService {
        tenant: string;
    }

    export interface ILogin {
        errors: Array<any>;
        login: () => void;
        busy: boolean;
        cfncURL: string;
    }

    export class LoginController implements ILogin {
        static controllerId: string = "loginController";
        static $inject: any = ["$scope", "$routeParams", "$location", "authentication", "resetPassword", "config"];
        errors: Array<any> = [];
        busy: boolean;
        loginInfo: ILoginModel;
        cfncURL: string;

        constructor(public $scope: ILoginControllerScope,
            private $routeParams: ILoginRouteParams,
            private $location: angular.ILocationService,
            private authentication: IAuthenticationProviderService,
            private resetPassword: IResetPassword,
            private config: ICrossroadsConfig) {

            authentication.redirectAuthenticated("/");
            this.busy = false;

            this.cfncURL = config.cfncUrl;
        }
        forgotPassword = () => {
            this.resetPassword.resetPasswordInfo = {
                client_id: null,
                userName: null,
                password: null,
                rememberMe: false,
                grant_type: null,
                tenant: this.$routeParams.tenant,
                needSecurityQuestions: false
            };
            this.$location.path("/login/forgotPassword");
        };

        public login = () => {
            var self = this;

            self.errors = [];
            if (self.$scope.loginForm.$valid) {
                self.busy = true;
                self.loginInfo.tenant = self.$routeParams.tenant;
                self.authentication.proCenterLogin(self.loginInfo).then(result => {
                    self.busy = false;
                }, reason => {
                    if (reason.status === 400) {
                        self.errors.push(reason.data.message);
                    }
                    else {
                        self.errors.push("An error occurred while logging in. Please try again later.");
                        self.busy = false;
                    }

                    self.busy = false;
                });
            }
        };

    }


    angular.module("app").controller(LoginController.controllerId, LoginController);
}
/// <reference path="../app.ts" />
namespace Advant.Documents {

    interface IPendingRequests {
        requests: IDocumentsRequest[];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        searchFilter: string;
    }


    class PendingRequests implements IPendingRequests {
        static controllerId: string = "pendingDocumentsRequests";
        static $inject: any = ["$scope", "Restangular", "common", "config", "Upload"];
        public dataAvailable: boolean;
        public requests: IDocumentsRequest[];
        public page: number;
        public pageSize: number;
        public totalCount: number;
        public sortBy: string;
        public sortDirection: string;
        public totalPages: number;
        public startRow: number;
        public endRow: number;
        public searchFilter: string;
        public uploadingDocument: boolean;

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        constructor(private $scope: angular.IScope,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private Upload: angular.angularFileUpload.IUploadService) {

            this.log = common.logger.getLogFn(PendingRequests.controllerId);
            this.logError = common.logger.getLogFn(PendingRequests.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(PendingRequests.controllerId, "success");
            this.requests = [];

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "DateRequested";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            this.uploadingDocument = false;

            this.activate();

            $scope.$on(config.events.customerChanged, (event) => {
                this.activate();
            });
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getPendingRequests()], PendingRequests.controllerId)
                .then(result => {
                    self.log("Activated Pending Requests View", null, false);

                });
        }


        getPendingRequests = () => {
            this.requests = [];

            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortDirection: this.sortDirection,
                sortBy: this.sortBy,
                crmId: this.common.getUser().activeCustomer,
                filter:this.searchFilter
            };

            return this.Restangular.all("documentsrequests/pending").getList(queryParams).then((result: any) => {
                this.requests = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }

                return result;
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the documents request", reason);
                }
                return null;
            });
        }

        searchUsers = (): void => {
            this.common.debouncedThrottle("PendingDocumentSearch", () => { this.page = 1; this.getPendingRequests(); }, 400);
        };

        sort(sortValue: string) {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
                this.sortDirection = "ASC";
            }
            this.page = 1;
            this.getPendingRequests();
        }

        uploadAttachment = (documentsRequestId, file) => {
            this.uploadingDocument = true;
            this.Upload.upload(<any>{
                url: this.config.apiHost + "/" + documentsRequestId + "/attachment",
                method: "POST",
                file: file
            }).then((response) => {
                this.uploadingDocument = false;
                this.activate();
            });
        }
    }

    angular.module("app").controller(PendingRequests.controllerId, PendingRequests);
}

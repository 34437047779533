/// <reference path="../app.ts" />
namespace Advant.Documents {
    "use strict";

    export interface IShellScope extends angular.IScope {
        vm: Shell;
    }

    export interface IShellController {
        busyMessage: string;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isBusy: boolean;
        showSplash: boolean;
        spinnerOptions: SpinnerOptions;
        toggleSpinner: (on: boolean) => void;
        topBarIsCollapsed: boolean;
        sideBarIsCollapsed: boolean;
        userInfo: IUserInfoModel;
        cfncURL: string;
    }

    export class Shell implements IShellController {
        static controllerId: string = "shell";
        static $inject: any = ["$rootScope", "$location", "Restangular", "common", "config", "authentication", "Idle"];

        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        logWarn: (msg, data?, showHowl?, makeSticky?) => void;
        events: any;
        busyMessage: string;
        isBusy: boolean;
        showSplash: boolean;
        spinnerOptions: SpinnerOptions;
        topBarIsCollapsed: boolean;
        sideBarIsCollapsed: boolean;
        userInfo: IUserInfoModel;
        cfncURL: string;
        profile: IUserViewModel;
        customers: any[];
        selectedCustomer: any;

        constructor(private $rootScope: ICrossRoadsRootScope,
            private $location: angular.ILocationService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private authentication: IAuthenticationProviderService,
            private Idle: angular.idle.IIdleService) {


            authentication.authenticate();

            this.userInfo = common.getUser();
            this.Idle.watch();
            this.logError = common.logger.getLogFn(Shell.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(Shell.controllerId, "success");
            this.logWarn = common.logger.getLogFn(Shell.controllerId, "warn");
            this.events = config.events;
            this.topBarIsCollapsed = true;
            this.sideBarIsCollapsed = true;

            this.busyMessage = "Please wait ...";
            this.isBusy = true;
            this.showSplash = true;
            this.spinnerOptions = {
                radius: 30,
                lines: 15,
                length: 20,
                width: 9,
                speed: 1.5,
                corners: 1.0,
                trail: 70,
                color: "#0E81B2"
            };


            this.cfncURL = this.config.cfncUrl;

            this.activate();


            var vm = this;
            $rootScope.$on("$routeChangeStart",
                (event, next, current) => { vm.toggleSpinner(true); }
            );

            $rootScope.$on(config.events.controllerActivateSuccess,
                (data) => { vm.toggleSpinner(false); }
            );

            $rootScope.$on(config.events.spinnerToggle,
                (event, data) => {
                    var eventData: any = data; // converts the IAngularEvent back to a dynamic type so we can view the "show" property that was set by the ICommonService
                    vm.toggleSpinner(eventData.show);
                });

            this.userInfo.activeCustomer

            $rootScope.$on(this.config.events.customerChanged, (event) => {
                this.userInfo = this.common.getUser();
            });
        }

        private changedCustomer = () => {
            sessionStorage[CrossroadsStorage.activeCustomer] = this.selectedCustomer.crmId;
            this.userInfo = this.common.getUser();
            this.common.$broadcast(this.config.events.customerChanged, this.selectedCustomer.crmId);
        };

        activate() {
            var self = this;
            this.logSuccess("Documents Portal loaded!", null, false);
            this.common.activateController([this.loadAvailableCustomers()], Shell.controllerId)
                .then(() => {
                    self.showSplash = false;
                });
        }

        private loadAvailableCustomers = (): angular.IPromise<any> => {
            return this.Restangular.all("customers").getList().then(result => {
                this.customers = [];
                if (result.length > 0) {
                    var selectedCrmId = this.common.getUser().activeCustomer;
                    this.selectedCustomer = _.find(result, (c) => { return c.crmId == selectedCrmId });
                    if (!this.selectedCustomer) {
                        this.selectedCustomer = result[0];
                        this.changedCustomer();
                    }
                    this.customers = result;
                }
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the organizations", reason);
            });
        };


        getProfile = () => {
            return this.Restangular.all("account").one("profile").get().then((result) => {
                this.profile = result;
            }, (reason) => {
                this.logError("Failed to retrieve profile data.", reason, true);
            });
        };


        toggleSpinner(on: boolean) { this.isBusy = on; }


        stopCloseOnEdit = ($event: angular.IAngularEvent) => {
            $event.preventDefault();
            $event.stopPropagation();
        };

    }

    angular.module("app").controller(Shell.controllerId, Shell);
}
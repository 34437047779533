/// <reference path="common.ts" />
namespace Advant.Documents {
    export interface ISpinnerService {
        spinnerHide: () => void;
        spinnerShow: () => void;
    }

    export class SpinnerService implements ISpinnerService {
        static serviceId: string = "spinner";

        constructor(private common: ICommonService, private commonConfig) {
        }

        spinnerHide() { this.spinnerToggle(false); }

        spinnerShow() { this.spinnerToggle(true); }

        private spinnerToggle(show) {
            // injects a show property to the IAngularEvent type this is picked up by the shell controller
            this.common.$broadcast(this.commonConfig.config.spinnerToggleEvent, { show: show });
        }
    }

    // Update the app1 variable name to be that of your module variable
    common.factory(SpinnerService.serviceId, ["common", "commonConfig", (common, commonConfig) =>
        new SpinnerService(common, commonConfig)
    ]);
}

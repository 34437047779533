/// <reference path="../app.ts" />
namespace Advant.Documents {
    export interface IPasswordResetModel extends ILoginModel {
        needSecurityQuestions: boolean;
    }

    export interface IResetPassword {
        resetPasswordInfo: IPasswordResetModel;

    }

    export class ResetPassword implements IResetPassword {
        static serviceId: string = "resetPassword";
        public resetPasswordInfo: IPasswordResetModel;

    }

    angular.module("app").factory(ResetPassword.serviceId, [() =>
        new ResetPassword()
    ]);

}
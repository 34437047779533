/// <reference path="../app.ts" />

namespace Advant.Documents {

    export interface IAdvUserLink extends angular.IDirective {
    }

    export interface IuserLinkScope extends angular.IScope {
        userInfo: IUserInfoModel;
        logoff();
    }

    export class IUserLink implements IAdvUserLink {
        static directiveId: string = "advUserLink";
        restrict: string = "EA";
        replace: boolean = true;
        scope: any = {};
        templateUrl: string = "/app/directives/advUserLink.html";

        constructor(private authentication: AuthenticationProviderService) {
        }

        link = (scope?: IuserLinkScope, element?, attr?) => {
            scope.userInfo = this.authentication.getUser();
            scope.logoff = () => {
                this.authentication.logout().then(result=> {
                    scope.userInfo = undefined;
                });
            };

            //  Set an event to fire when the user information changes in local storage
            scope.$watch((): string => {
                return sessionStorage[CrossroadsStorage.userName];
            }, (newValue, oldValue) => {
                    if (newValue && (newValue !== oldValue)) {
                        scope.userInfo = this.authentication.getUser();
                    }
                });
        };
    }

    angular.module("app").directive(IUserLink.directiveId, ["authentication", authentication =>
        new IUserLink(authentication)
    ]);
}
/// <reference path="../app.ts" />
namespace Advant.Documents {
    "use strict";

    interface IConvertToNumber extends angular.IDirective {
    }

    interface IConvertToNumberScope extends angular.IScope {
    }

    interface IConvertToNumberAttributes extends angular.IAttributes {
    }

    convertToNumber.$inject = ["$window"];
    function convertToNumber($window: angular.IWindowService): IConvertToNumber {
        return {
            restrict: "A",
            require: "ngModel",
            link: link
        };

        function link(scope: IConvertToNumberScope, element: angular.IAugmentedJQuery, attrs: IConvertToNumberAttributes, modelController: angular.INgModelController) {
            modelController.$parsers.push((val) => {
                return parseInt(val, 10);
            });
            modelController.$formatters.push((val) => {
                return "" + val;
            });

        }
    }

    angular.module("app").directive("convertToNumber", convertToNumber);
}
/// <reference path="../app.ts" />

namespace Advant.Documents  {
    interface IPasswordChangeScope extends angular.IScope {
        vm: PasswordChange;
        changeForm: angular.IFormController;
    }

    interface IPasswordChange {
        changeInfo: IChangePasswordModel;
    }

    class PasswordChange implements IPasswordChange {
        static controllerId: string = "passwordChange";
        static $inject: any = ["$scope", "$location", "authentication", "resetPassword"];
        changeInfo: IChangePasswordModel;
        securityChangeInfo: IForceSecurityQuestionChangeModel;
        errors: Array<any> = [];
        busy: boolean;
        loginInfo: ILoginModel;

        constructor(private $scope: IPasswordChangeScope,
            private $location: angular.ILocationService,
            private authentication: IAuthenticationProviderService,
            private resetPassword: IResetPassword) {

            this.changeInfo = {
                userName: resetPassword.resetPasswordInfo.userName,
                originalPassword: resetPassword.resetPasswordInfo.password,
                password: "",
                confirmPassword: "",
                tenant: resetPassword.resetPasswordInfo.tenant
            };

            if (resetPassword.resetPasswordInfo.needSecurityQuestions) {
                this.securityChangeInfo = {
                    userName: resetPassword.resetPasswordInfo.userName,
                    password: resetPassword.resetPasswordInfo.password,
                    question: "",
                    answer: "",
                    tenant: resetPassword.resetPasswordInfo.tenant
                };
            }

            this.busy = false;
        }

        changePassword() {
            var self = this;
            self.errors = [];
            if (self.$scope.changeForm.$valid) {
                self.busy = true;

                self.authentication.forcePasswordChange(self.changeInfo).then(result=> {
                    self.loginInfo = {
                        client_id: self.resetPassword.resetPasswordInfo.client_id,
                        userName: self.resetPassword.resetPasswordInfo.userName,
                        password: self.changeInfo.password,
                        rememberMe: self.resetPassword.resetPasswordInfo.rememberMe,
                        tenant: self.resetPassword.resetPasswordInfo.tenant,
                        grant_type: self.resetPassword.resetPasswordInfo.grant_type
                    };
                    if (self.resetPassword.resetPasswordInfo.needSecurityQuestions) {
                        self.securityChangeInfo.password = self.changeInfo.password;
                        self.authentication.forceSecurityQuestionChange(self.securityChangeInfo).then(result => {
                            self.login();
                        }, reason => {
                            if (reason.data.error_description) {
                                self.errors.push(reason.data.error_description);
                            } else {
                                self.errors.push("An error occurred while logging in. Please try again later.");
                            }

                            self.busy = false;

                        });
                    } else {
                        self.login();
                    }
                }, reason => {
                    if (reason.data.error_description) {
                        self.errors.push(reason.data.error_description);
                    } else if (reason.data.modelState && reason.data.modelState.password) {
                        self.errors.push(reason.data.modelState.password[0]);
                    } else {
                        self.errors.push("An error occurred while logging in. Please try again later.");
                    }

                    self.busy = false;

                });
            }
        }

        login() {
            var self = this;

            self.errors = [];

            self.authentication.login(self.loginInfo).then(result=> {
                self.busy = false;
            }, reason=> {
                if (reason.data.error_description) {
                    self.errors.push(reason.data.error_description);
                } else {
                    self.errors.push("An error occurred while logging in. Please try again later.");
                }

                self.busy = false;
            });
        }
    }

    angular.module("app").controller(PasswordChange.controllerId, PasswordChange);
}
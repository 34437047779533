/// <reference path="../app.ts" />

namespace Advant.Documents  {
    interface IConfirmPasswordChangeScope extends angular.IScope {
        vm: ConfirmPasswordChange;
        changeForm: angular.IFormController;
    }

    interface IConfirmPasswordChange {
        changeInfo: IChangeUserPasswordWithKeyModel;
    }

    class ConfirmPasswordChange implements IConfirmPasswordChange {
        static controllerId: string = "confirmPasswordChange";
        static $inject: any = ["$scope", "$location", "authentication"];
        changeInfo: IChangeUserPasswordWithKeyModel;
        errors: Array<any> = [];
        busy: boolean;
        loginInfo: ILoginModel;
        showCancelInfo: boolean;

        constructor(private $scope: IConfirmPasswordChangeScope,
            private $location: angular.ILocationService,
            private authentication: IAuthenticationProviderService) {
            var key = $location.search().key;
            this.showCancelInfo = !key;
            this.changeInfo = {
                key: key,
                password: "",
                confirmPassword: ""
            };

            this.busy = false;
        }

        changePassword() {
            var self = this;
            self.errors = [];
            if (self.$scope.changeForm.$valid) {
                self.busy = true;

                self.authentication.changePasswordWithKey(self.changeInfo).then(result=> {
                    self.$location.path("/login");
                }, reason => {
                    if (reason.data.error_description) {
                        self.errors.push(reason.data.error_description);
                    } else if (reason.data.exceptionMessage) {
                        self.errors.push(reason.data.exceptionMessage);
                    } else {
                        self.errors.push("An error occurred while trying to change the password. Please try again later.");
                    }

                    self.busy = false;

                });
            }
        }
    }

    angular.module("app").controller(ConfirmPasswordChange.controllerId, ConfirmPasswordChange);
}
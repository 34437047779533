namespace Advant.Documents {
    export class CrossroadsStorage {
        public static userName: string = "userInfo.userName";
        public static firstName = "userInfo.firstName";
        public static lastName: string = "userInfo.lastName";
        public static fullName: string = "userInfo.fullName";
        public static tenant: string = "userInfo.tenant";
        public static loginProvider: string = "userInfo.loginProvider";
        public static hasRegistered: string = "userInfo.hasRegistered";
        public static passwordChangedOn: string = "userInfo.passwordChangedOn";
        public static timeZone: string = "userInfo.timeZone";
        public static proCenterId: string = "userInfo.proCenterId";
        public static accessToken: string = "accessToken";
        public static refreshToken: string = "refreshToken";
        public static redirectTarget: string = "redirectTarget";
        public static rememberMe: string = "rememberMe";
        public static activeCustomer: string = "activeCustomer";
        
        public static canEditOrganization: string = "userInfo.canEditOrganization";
        public static canEditProgram: string = "userInfo.canEditProgram";
        public static canViewUsers: string = "userInfo.canViewUsers";
        public static canViewReports: string = "userInfo.canViewReports";
        public static canViewCreditCardReports: string = "userInfo.canViewCreditCardReports";
        public static canEditReports: string = "userInfo.canEditReports";
        public static canEditApplications: string = "userInfo.canEditApplications";
        public static canEditApplicationForms: string = "userInfo.canEditApplicationForms";
        public static canViewApplicants: string = "userInfo.canViewApplicants";
        public static canCreateAppExports: string = "userInfo.canCreateAppExports";
        public static canShareAppExports: string = "userInfo.canShareAppExports";
        public static canExportApplicants: string = "userInfo.canExportApplicants";
        public static canViewProspects: string = "userInfo.canViewProspects";
        public static canEditProspects: string = "userInfo.canEditProspects";
        public static canExportProspects: string = "userInfo.canExportProspects";
        public static canCreateProspectExports: string = "userInfo.canCreateProspectExports";
        public static canCreateTranscriptExports: string = "userInfo.canCreateTranscriptExports";
        public static canExportTranscripts: string = "userInfo.canExportTranscripts";
        public static canUpdateTaap: string = "userInfo.canUpdateTaap";
        public static canUpdateCollegeProfile: string = "userInfo.canUpdateCollegeProfile";
        public static canSearchResidency: string = "userInfo.canSearchResidency";
        public static systemAdmin: string = "userInfo.systemAdmin";
    
        public static expiresOn: string = "expiresOn";
        public static refreshTokenExpiresIn: string = "refreshTokenExpiresIn";
        public static hasSharedExportProject: string = "hasSharedExportProject";
    }
} 